<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle v-if="!propOpenedInModal">
            <h4 class="card-title">{{cvCardTitle}}</h4>
          </template>
          <template v-slot:body>
            <p v-show="!propOpenedInModal">{{cvCardSubHeader}}</p>
            <form action="#" v-if="vmGPathrRoadmapExtraFormData && Object.keys(vmGPathrRoadmapExtraFormData).length > 0">
              <div class="form-row">

  <div class="col-md-12 mb-3">
    <label for="validationname">
      {{cvNameLabel}}</label
    >
    <input
      v-model="vmGPathrRoadmapExtraFormData.name"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationname_key">
      {{cvNameKeyLabel}}</label
    >
    <input
      v-model="vmGPathrRoadmapExtraFormData.name_key"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationtitle">
      {{cvTitleLabel}}</label
    >
    <input
      v-model="vmGPathrRoadmapExtraFormData.title"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationdescription">
      {{cvDescriptionLabel}}</label
    >
    <textarea
      v-model="vmGPathrRoadmapExtraFormData.description"
      type="text"
      class="form-control textarea"
      required></textarea>
</div>
  <div class="col-md-12 mb-3">
    <label for="validationurl">
      {{cvUrlLabel}}</label
    >
    <input
      v-model="vmGPathrRoadmapExtraFormData.url"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationimage">
      {{cvImageLabel}}</label
    >
    <input
      v-model="vmGPathrRoadmapExtraFormData.image"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationcareer_name">
      {{cvCareerNameLabel}}</label
    >
    <input
      v-model="vmGPathrRoadmapExtraFormData.career_name"
      type="text"
      class="form-control"
      required
    />
</div>
  <div class="col-md-12 mb-3">
    <label for="validationcareer_key">
      {{cvCareerKeyLabel}}</label
    >
    <input
      v-model="vmGPathrRoadmapExtraFormData.career_key"
      type="text"
      class="form-control"
      required
    />
</div>
              </div>
              <div class="form-group">
                <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                   <b-spinner label="Spinning"></b-spinner>
                </button>
                <button type="button" class="btn btn-primary" @click="gpathRoadmapExtraEdit()">{{cvSubmitBtn}}</button>
              </div>
            </form>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>

<script>
import { GPathrRoadmapExtras } from "../../../FackApi/api/GPathrRoadmapExtra"
import ApiResponse from "../../../Utils/apiResponse"
import { socialvue } from "../../../config/pluginInit"
import userPermission from "../../../Utils/user_permission"

export default {
  name: "GPathrRoadmapExtraEdit",
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGPathrRoadmapExtraObj: {
      type: Object,
      default: function () {
        return {
          "name": "",
          "name_key": "",
          "title": "",
          "description": "",
          "url": "",
          "image": "",
          "career_name": "",
          "career_key": ""
        }
      }
    }
  },
  data () {
    return {
      apiName: "gpath_roadmap_extra_edit",
      cvCardTitle: "Edit GPaths Roadmap Extra",
      cvCardSubHeader: "Edit GPaths Roadmap Extra ",
      cvSubmitBtn: "Edit",
      cvNameLabel: "name",
      cvNameKeyLabel: "name key",
      cvTitleLabel: "title",
      cvDescriptionLabel: "description",
      cvUrlLabel: "url",
      cvImageLabel: "image",
      cvCareerNameLabel: "career name",
      cvCareerKeyLabel: "career key",
      showToast: false,
      cvLoadingStatus: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "GPathrRoadmapExtra Edit Response",
      vmGPathrRoadmapExtraFormData: {}
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  mounted () {
    socialvue.index()
    this.gpathRoadmapExtraView()
  },
  methods: {
    /**
     * validate
     */
    validate () {
      try {
        for (let index in this.vmGPathrRoadmapExtraFormData) {
          if (!this.vmGPathrRoadmapExtraFormData[index]) {
            this.toastMsg = "Missing " + index.split("_").join(" ")
            this.toastVariant = "danger"
            this.showToast = true
            return false
          }
        }
        return true
      }
      catch (err) {
        console.error("Exception occurred at validate() and Exception:", err.message)
        return null
      }
    },
    /**
     * gpathRoadmapExtraView
     */
    async gpathRoadmapExtraView () {
      try {
        if (this.propOpenedInModal) {
          this.vmGPathrRoadmapExtraFormData = this.propGPathrRoadmapExtraObj
        }
        else {
          let gpathRoadmapExtraId = this.$route.params.gprme_id
          let gpathRoadmapExtraViewResp = await GPathrRoadmapExtras.gpathRoadmapExtraView(this, gpathRoadmapExtraId)

          if (gpathRoadmapExtraViewResp && gpathRoadmapExtraViewResp.resp_status) {
            this.vmGPathrRoadmapExtraFormData = gpathRoadmapExtraViewResp.resp_data.data
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapExtraView() and Exception:", err.message)
      }
    },
    /**
     * gpathRoadmapExtraEdit
     */
    async gpathRoadmapExtraEdit () {
      try {
        // if (!this.validate()) {
        //   return false
        // }
        this.cvLoadingStatus = true
        let gpathRoadmapExtraAddResp = await GPathrRoadmapExtras.gpathRoadmapExtraEdit(this, this.vmGPathrRoadmapExtraFormData)
        await ApiResponse.responseMessageDisplay(this, gpathRoadmapExtraAddResp)

        if (gpathRoadmapExtraAddResp && !gpathRoadmapExtraAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitCloseGPathrRoadmapExtraEditModal", this.vmGPathrRoadmapExtraFormData)
        }
      }
      catch (err) {
        console.error("Exception occurred at gpathRoadmapExtraEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    }
  }
}
</script>
<style scoped>
 textarea{
    height: 195px !important;
    overflow-y: scroll;
    line-height: 18px !important;
  }
</style>
